@import '_vars';

// EL HOME
.actualidad {
    @include tabletChica {
        padding-top: 90px; 
    }
    .boton-filtrar {
        &.active {
            background-color: $morado !important;
            border-color: transparent !important;
        }
        &.active svg {
            fill: $blanco !important;
        }
    }
    .filtro-grande {
        display: none;
        &.active {
            display: block;
        }
        select {
            border: none;
            border-bottom: 2px solid rgba($negroPuro, .2);
            flex: 3;
            color: $gris;
            font-size: 12px;
            padding-bottom: 10px;
            font-weight: bold;
            outline: none;
        }
        &.columna-borde {
            border-top: $borde-seccion-2;
        }
        .titulos-filtros {
            flex: 1;
            span {
                display: block;
                margin-bottom: 0;
                color: $gris;
                font-weight: bold;
                font-size: 12px;
                margin-bottom: 20px;
            }
        }
        .filtro-singular {
            margin-bottom: 20px;
        }
    }
    .contenedor-actualidad {
        border-bottom: $borde-seccion;
    }
    .columna-borde {
        border-left: $borde-seccion;
    }
    .entrada-individual {
        flex: 0 0 calc(50% - 20px);
        margin-bottom: 40px;
    }
    .atomo-bg {
        &.actualidad {
            position: absolute;
            top: 275px;
            right: 0;
        }
    }
}

.webinar {
    .contenedor-webinar {
        max-width: 1240px;
    }
    .fila {
        background-color: $morado;
        @include tabletChica {
            // max-height: 340px;
        }
        .titulo-interno {
            color: $blanco;
        }
        .parrafo-interno {
            color: rgba($blanco, .8);
            &.duracion {
                margin-top: 10px;
                font-weight: bold;
            }
        }
    }
    .columna-izquierda {
        color: $blanco;
    }
    .icono {
        &.play {
            fill: $blanco;
        }
    }
    .btn {
        &.video-principal {
            &:hover {
                .icono {
                    &.play {
                        fill: $morado;
                    }
                }
            }
        }
    }
    .columna-derecha {
        .video {
            width: 100%;
            height: calc(100% + 40px);
        }
        @include tabletChica {
            position: relative;
            .video {
                position: absolute;
                top: -20px;
                width: 90%; 
            }
        }
    }
}

.blog-foros {
    .cards-blog {
        .card {
            flex: 0 0 calc(50% - 5px);
            @include tabletChica {
                flex: 0 0 calc(50% - 20px);
            }
        }
    }
}

.cta {
    background-color: $blanco2;
    padding: 60px 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include laptop {
        background-image: url(../img/cta-newsletter-biospifar.png);
    }
    form {
        input[type='email'] {
            display: block;
            width: 100%;
        }
    }
    button {
        display: block;
        width: 100%;
    }
}

.proximo-evento {
    .columna-derecha {
        border: $borde-seccion;
    }
}

/***********************PÁGINAS INTERNAS**************************/
// MISIÓN VISIÓN
.mision-vision {
    position: relative;
    .titulo {
        font-size: 5vmin;
    }
    .atomo-bg {
        &.mision-vision {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

// ALIDOS ESTRATEGICOS
.alidos-estrategicos {
    position: relative;
    .galeria {
        .thumb-galeria {
            flex: 0 0 calc(20%);
            margin-bottom: 60px;
        }
        
    }
    .atomo-bg {
        &.aliados-estrategicos {
            position: absolute;
            bottom: -40px;
            right: 0;
        }
    }
}

// TRABAJE CON NOSOTROS
.trabaje-con-nosotros {
    background-image: url(../img/ingeniera-bg-biospifar-registro.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    position: relative;
    .titulo {
        flex: 0 0 25%;
    }
    .formulario {
        @include tabletChica {
            min-width: 300px;
        }
    }
    .contenedor-formulario {
        background-image: linear-gradient(180deg, rgba($morado, 1) 0%, rgba($morado2, 1) 35%, rgba($morado3, 1) 100%);
        border-radius: $radius-10;
        font-size: 2.8vmin;
        color: $blanco;
        @include tabletChica {
            font-size: 2vmin;
        }
        .input-group {
            position: relative;
            margin-bottom: 40px;
        }
        input[type='text'],
        input[type='tel'],
        input[type='email'] {
            display: block;
            width: 100%;
            border: none;
            outline: none;
            background-color: transparent;
            border-bottom: 2px solid rgba($blanco, .2);
            color: $blanco;
            transition: all .6s ease;
            &:focus {
                border-bottom: 2px solid rgba($blanco, .4);
            }
        }
        input[type='file'] {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }
        .label {
            position: absolute;
            top: 0;
            left: 0;
            color: rgba($blanco, .7);
            cursor: text;
            transition: all .6s ease;
            &.active {
                top: -20px;
                font-size: 2.4vmin;
                @include tabletChica {
                    font-size: 1.6vmin;
                }
            }
        }
    }
    .atomo-bg {
        &.trabaje {
            position: absolute;
            bottom: 40px;
            right: 0;
        }
    }
}

// WEBINAR - PÁGINA INTERNA
.webinar {
    &.pagina-interna {
        // background-image: linear-gradient(90deg, rgba($moradoClaro, .9) 0%, rgba($moradoClaro, .9) 35%, rgba($moradoClaro, .9) 100%), url(/img/bg-webinar.jpg);
        // background: url(http://img.youtube.com/vi/l_C5fF6iHjk/maxresdefault.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding-top: 90px;
        position: relative;
        z-index: $index-0;
        &::before {
            content: "";
            background-image: linear-gradient(90deg, rgba($moradoClaro, .9) 0%, rgba($moradoClaro, .9) 35%, rgba($moradoClaro, .9) 100%);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: $index--10;
        }
        .titulo {
            color: $blanco;
        }
        .parrafo-interno {
            &.duracion {
                margin-top: 10px;
                font-weight: bold;
            }
        }
        .fila {
            background-color: rgba($morado, .6);
            min-height: 450px;
            .carousel-grid-video {
                @include tabletChica {
                    position: relative;
                    top: -90px; 
                }
                .video-singular {
                    background-color: $blanco;
                    padding: 15px;
                    border-radius: $radius-5;
                    .video-fluid {
                        height: 200px;
                    }
                    .parrafo-interno {
                        &.duracion {
                            color: $negroClaro;
                        }
                    }
                }
            }
            .controles-grid {
                margin-top: 20px;
                @include tabletChica {
                    position: relative;
                    top: -60px;
                    margin-top: 0;
                }
            }
        }
        .swiper-scrollbar {
            width: 33%; 
            height: 2px; 
            background-color: rgba($blanco, .4);
            @include tabletGrande {
                width: 60%;
            }
            .swiper-scrollbar-drag {
                background-color: $moradoClaro;
            }
        }
        .flechas {
            color: $blanco;
            user-select: none;
            outline: none !important;
            .icono {
                margin: 0 20px;
                font-size: 16px;
                outline: none !important;
            }
        }
    }
}

// PRODUCTOS
.productos {
    position: relative;
    .opcion-filtro {
        width: calc(50% - 8px);
        transition: all .6s ease;
        @include tabletChica {
            width: 120px;
        }
        @include tabletGrande {
            width: 165px;
        }
    }
    input[type='radio'] {
        display: none;
        &:checked + label {
            background-color: $morado;
            color: $blanco;
        }
    }
    .productos-grid {
        .producto-singular {
            flex: 0 0 calc(50% - 10px);
            margin-bottom: 50px;
            @include tabletChica {
                flex: 0 0 calc(33.33% - 10px);
            }
            @include tabletGrande {
                flex: 0 0 calc(25% - 10px);
            }
        }
    }
    .atomo-bg {
        &.productos {
            position: absolute;
            right: 0;
            top: 200px;
        }
    }
}

// PRODUCTO SINGULAR
.producto-singular {
    position: relative;
    .producto-singular-grid {
        .producto-singular {
            flex: 0 0 calc(50%);
            margin-bottom: 50px;
        }
    }
    .opcion-filtro {
        width: calc(50% - 8px);
        transition: all .6s ease;
        @include tabletChica {
            width: 120px;
        }
        @include tabletGrande {
            width: 165px;
        }
    }
    input[type='radio'] {
        display: none;
        &:checked + label {
            background-color: $morado;
            color: $blanco;
        }
    }
    .columna-derecha {
        background-color: $blanco2;
    }
    .atomo-bg {
        &.producto {
            position: absolute;
            top: 200px;
            right: 0;
        }
    }
}

// LOGIN-REGISTRO
.login-registro {
    background-image: url(../img/ingeniera-bg-biospifar-registro.jpg);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    min-height: 100vh;
    .formulario {
        @include tabletChica {     
            min-width: 300px;
            max-width: 400px;
        }
    }
    .formulario-contenedor {
        background-image: linear-gradient(180deg, rgba($morado, 1) 0%, rgba($morado2, 1) 35%, rgba($morado3, 1) 100%);
        border-radius: $radius-10;
        font-size: 2.8vmin;
        color: $blanco;
        @include tabletChica {
            font-size: 2vmin;
        }
        .enlace-interno-grande {
            font-size: 3vmin;
            color: rgba($blanco, .7);
            @include tabletChica {
                font-size: 2.6vmin;
            }
            &:hover {
                color: $blanco;
            }
            &.active {
                color: $rosa;
                border-bottom: 1px solid $rosa;
                font-weight: bold;
            }
        }
        .input-group {
            position: relative;
            margin-bottom: 40px;
        }
        input[type='text'],
        input[type='email'],
        input[type='tel'],
        input[type='password'] {
            display: block;
            width: 100%;
            border: none;
            outline: none;
            background-color: transparent;
            border-bottom: 2px solid rgba($blanco, .2);
            color: $blanco;
            transition: all .6s ease;
            &:focus {
                border-bottom: 2px solid rgba($blanco, .4);
            }
        }
        .label {
            position: absolute;
            top: 0;
            left: 0;
            color: rgba($blanco, .7);
            cursor: text;
            transition: all .6s ease;
            &.active {
                top: -20px;
                font-size: 2.4vmin;
                @include tabletChica {
                    font-size: 1.6vmin; 
                }
            }
        }
    }
    .atomo-bg {
        &.registro-login {
            position: absolute;
            bottom: -90px;
            right: 0;
        }
    }
}

// HISTORIA
// PÁGINA HISTORÍA
.historia {
    background-color: $blanco2;
    .titulo-historia {
        color: $azul !important;
    }
    .historia-grid {
        .columna {
            // background-color: $blanco;
            &.izquierda {
                flex: 1;
                background-color: $blanco;
                @include laptop {
                    flex: 0 0 calc(40% - 40px);
                } 
            }
            &.derecha {
                background-color: $blanco;
                flex: 0 0 50%;
                @include laptop {
                    display: block;
                    flex: 0 0 calc(60% - 40px);
                }
                .logo {
                    width: 100%;
                    @include tabletChica {
                        width: 350px;
                    }
                    @include laptop {
                        position: relative;
                        left: -40px;
                    }
                }
                .atomo-bg {
                    &.linea-tiempo {
                        position: absolute;
                        top: 50%;
                        right: 0;
                    }
                }
            }
        }
        .timeline {
            position: relative;
            left: -50%;
            @include laptop {
                left: calc(-55% - 2px);
            }
            .fechas {
                padding: 0;
                .fecha-entrada-singular {
                    position: relative;
                    margin-top: 60px;
                    &:first-of-type {
                        margin-top: 90px;
                    }
                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        left: 50%;
                        border: 2px dashed $morado2;
                        height: 100%;
                    }
                    &:first-of-type::before {
                        border-color: $morado;
                    }
                    &:last-of-type::before {
                        border-color: $morado3;
                    }
                    .fecha {
                        font-size: 4vmin;
                        color: $morado2;
                        font-weight: bold;
                        text-align: center;
                        width: 100%;
                        display: block;
                        position: relative;
                        top: -40px;
                    }
                    &:first-of-type .fecha {
                        color: $morado;
                    }
                    &:last-of-type .fecha {
                        color: $morado3;
                    }
                    .historias-interiores {
                        padding: 0;
                        .historia-interior-singular {
                            width: 80%;
                            @include tabletChica {
                                width: 50%;
                            }
                            @include laptop {
                                margin-bottom: 120px;
                            }
                            .textos {
                                position: relative;
                                padding: 20px 0;
                            }
                            .imagen {
                                position: absolute;
                                top: 30%;
                                width: 100%;
                                @include laptop {
                                    top: 0;
                                }
                                img {
                                    width: 100%;
                                }
                            }
                            &:nth-child(odd) {
                                float: right;
                                text-align: left;
                                clear: both;
                                position: relative;
                                .textos {
                                    left: 50%;
                                    @include tabletChica {
                                        left: 20%;
                                    }
                                }
                                .imagen {
                                    right: 62%;
                                    @include tabletChica {
                                        right: 100%;
                                    }
                                }
                            }
                            &:nth-child(even) {
                                float: left;
                                text-align: right;
                                clear: both;
                                position: relative;

                                .textos {
                                    right: 50%;
                                    @include tabletChica {
                                        right: 20%;
                                    }
                                }
                                .imagen {
                                    left: calc(62% + 4px);
                                    @include tabletChica {
                                        left: calc(100% + 4px);
                                    }
                                }
                            }
                        }
                        .frase-destacada {
                            color: $azul;
                            font-weight: bold;
                            width: 25%;
                            position: absolute;
                            right: -40%;
                            top: calc(50% - 70px);
                            display: none;
                            @include laptop {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}


// INVESTIGACIÓN
.investigacion {
    position: relative;
    .tarjetas-arriba {
        .card {
            border: $borde-seccion;
            flex: 0 0 calc(50% - 10px);
            margin-bottom: 20px;
            @include tabletChica {
                flex: 0 0 calc(20% - 10px);
            }
            .card-body {
                padding: 10px 0;
                .card-title {
                    margin-bottom: 0;
                }
            }
        }
    }
    .tarjetas-abajo {
        .card {
            border: $borde-seccion;
            // flex: 0 0 calc(33.33% - 10px);
            margin-bottom: 15px;
            @include tabletChica {
                flex: 0 0 calc(33.33% - 10px);
            }
            .btn {
                &.btn-outline-primary {
                    padding: 5px 20px;
                    margin-top: 20px;
                }
            }
        }
    }
    .atomo-bg {
        &.investigacion {
            position: absolute;
            top: 200px;
            right: 0;
        }
    }
}

// FOROS
.foros {
    position: relative;
    .foro-entrada {
        padding: 40px 0;
        border-bottom: 1px solid rgba($negroPuro, .2);
        .contenido-foro-entrada {
            width: 100%;
            @include tabletChica {
                width: 70%;
            }
        }
    }
    .atomo-bg {
        &.foros {
            position: absolute;
            top: 90px;
            right: 0;
            z-index: $index--10;
        }
    }
}

// BLOG 
.blog {
    position: relative;
    .contenedor-titulo-buscador {
        @include tabletChica {
            width: calc(50% + 53px);
        }
    }
    .tarjetas {
        .card {
            &.small {
                border: 1px solid rgba($negroPuro, .1);
                margin-right: 10px;
                text-align: center;
                margin-bottom: 20px;
                .card-body {
                    padding: 10px 0;
                    .card-title {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .fila-abajo {
        @include tabletChica {
            margin-top: -60px;
        }
    }
    .atomo-bg {
        &.blog {
            position: absolute;
            top: 200px;
            right: 0;
        }
    }
}

// ACTUALIDAD 
.actualidad-blog {
    .cards-blog {
        @include tabletChica {
            .card {
                flex: 0 0 calc(33.33% - 20px);
            }
        }
    }
}

// CALENDARIO EVENTOS
.calendario {
    position: relative;
    .calendario-contenedor {
        background-color: rgba($blanco3, .6);
    }
    // LOS BOTONES DE IZQUIERDA Y DERECHA
    .fc-button {
        background-color: transparent !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        color: $morado !important;
        .fc-icon {
            font-size: 64px !important;
        }
    }
    // EL TOOLBAR
    .fc-toolbar.fc-header-toolbar {
        background-color: $blanco !important;
        padding-left: 0 !important;
        @include tabletGrande {
            padding-right: 500px;
        }
    }
    // EL MES Y AÑO
    .fc-toolbar-title {
        font-size: 9vmin !important;
    }
    // LA TABLA
    .fc-scrollgrid {
        // background-color: rgba($blanco3, .6);
        border: none !important;
    }
    // LOS TH
    .fc-col-header-cell-cushion {
        color: $morado !important;
        padding-left: 20px !important;
        font-size: 4vmin;
    }
    .fc-scrollgrid-sync-inner {
        text-align: left !important;
    }
    .fc-theme-standard td, .fc-theme-standard th {
        border: none !important;
    }
    // NUMERO DÍA
    .fc-daygrid-day-top {
        display: unset !important;
        font-weight: bold;
        .fc-daygrid-day-number {
            color: $grisOscuro !important;
            font-size: 2.4vmin;
        }
    }
    // EL DÍA DE HOY
    .fc-day-today {
        background-color: $morado2 !important;
        .fc-daygrid-day-number {
            color: $blanco !important;
        }
        .fc-event-title {
            color: rgba($blanco, .8);
            overflow: unset !important;
        }
    }
    .fc-daygrid-event {
        white-space: normal !important;
    }
    // TITULO DEL EVENTO
    .fc-h-event {
        background: none !important;
        border: none !important;
    }
    .fc-event-title {
        color: $grisOscuro;
        font-size: 2.2vmin; 
        font-weight: bold;
        // overflow: unset !important;
    }
    .atomo-bg {
        position: absolute;
        &.calendario-eventos {
            top: 120px;
            right: 0;
        }  
    } 
}

// CREAR FORO
.crear-foro {
    background-image: url(../img/ingeniera-bg-biospifar-registro.jpg);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    min-height: 100vh;
    position: relative;
    .formulario {
        label {
            color: $azul;
            font-weight: bold;
            margin-bottom: 15px;
        }
        input[type='text']
        textarea {
            outline: none;
        }
        textarea {
            display: block;
            width: 100%;
            min-height: 100px;
            max-height: 200px;
        }
        input[type='file'] {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }
    }
    .atomo-bg {
        &.foro-crear {
            position: absolute;
            bottom: -20px;
            right: 0;
        }
    }
}

//FORO Y/O BLOG ENTRADA SINGULAR
.foro-blog-entrada-singular {
    position: relative;
    background-color: $blanco4;
    .autor {
        color: $azul;
        font-weight: bold;
    }
    .columna-borde {
        
        border-left: $borde-seccion;
    }
    p {
        margin-bottom: 20px;
    }
    textarea {
        min-height: 100px;
        max-height: 200px;
    }
    .entrada-comentario-individual {
        .foto-perfil {
            border-radius: $radius-50;
            width: 32px;
            height: 32px;
        }
    }
    .comentarios {
        .comentario-principal {
            padding-left: 0;
        }
        .entrada-comentario {
            padding-bottom: 20px;
        }
        // ESTAS SON LAS CLASES PARA EL LIKE
        .icono-like-border {
            fill: $azul;
            cursor: pointer;
            &.add-like-bg {
                background-color: $azul;
                fill: $blanco;
                border-radius: $radius-50;
            }
            &.remove-like-border {
                display: none;
            }
        }
        .icono-like-paint {
            fill: $azul;
            cursor: pointer;
            display: none;
            &.show-icono-like-paint {
                display: block;
            }
        }
        //HASTA AQUÍ
        .icono-comentarios {
            fill: $azul;
        }
        .numero-likes {
            color: $azul;
            font-weight: bold;
            font-size: 10px;
        }
        .numero-comentarios {
            color: $azul;
            font-weight: bold;
        }
        .numero-respuestas {
            color: $azul;
            font-weight: bold;
            font-size: 10px;
        }
        .nombre-comenta {
            color: $azul;
            font-weight: bold;
            font-size: 12px;
        }
        .tiempo-comenta {
            color: $azul;
            font-size: 12px;
        }
        .redes-sociales {
            a {
                color: $azul;
            }
        }
    }
    .atomo-bg {
        &.detalle-blog {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

// LISTA VIDEOS 
.lista-videos {
    position: relative;
    .columna-lista-videos {
        max-height: 600px;
        overflow-y: scroll;
    }
    .contenedor-video {
        max-width: 250px;
    }
    .video-grande {
        height: 350px;
    }
    .atomo-bg {
        &.videos-lista {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
}

// INVESTIGACIÓN SINGULAR   
.investigacion-singular {
    position: relative;
    .otras-investigaciones {
        margin-top: 80px;
        border-top: $borde-seccion;
        .otras-investigaciones-grid {
            .investigacion-singular {
                border: $borde-seccion;
                padding: 25px;
                border-radius: $radius-5;
            }
        }
    }
    .atomo-bg {
        &.singular-investigacion {
            position: absolute;
            top: 300px;
            right: 0;
        }
    }
}



// WEBINAR VIDEOS
.webinar-videos {
    .webinar-videos-grid {
        .card {
            flex: 0 0 calc(33.33% - 20px);
            margin-bottom: 50px;
            .video-fluid {
                height: 200px;
            }
            .parrafo-interno {
                &.duracion {
                    font-weight: bold;
                }
            }
        }
    }
    .boton-filtrar {
        &.active {
            background-color: $morado !important;
            border-color: transparent !important;
        }
        &.active svg {
            fill: $blanco !important;
        }
    }
    .filtro-grande {
        display: none;
        &.active {
            display: block;
        }
        &.columna-borde {
            border-top: $borde-seccion-2;
        }
        select {
            border: none;
            border-bottom: 2px solid rgba($negroPuro, .2);
            flex: 2;
            color: $gris;
            font-size: 12px;
            padding-bottom: 10px;
            font-weight: bold;
            outline: none;
            &:last-of-type {
                margin-left: 40px;
            }
        }
        .titulos-filtros {
            flex: 1;
            span {
                display: block;
                margin-bottom: 0;
                color: $gris;
                font-weight: bold;
                font-size: 12px;
                margin-bottom: 20px;
            }
        }
    }
    .atomo-bg {
        &.videos-webinar {
            position: absolute;
            top: 300px;
            right: 0;
        }
    }
}

// PERFIL USUARIO 
.perfil-usuario {
    background-image: url(../img/bg-wave-hero.png);
    background-size: cover;
    background-position: -90px bottom;
    background-repeat: no-repeat;
    min-height: 100vh;
    @include tabletChica {
        background-size: contain;
    }
    input[type='file'] {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    .imagen-perfil {
        position: relative;
        @include tabletChica {
            width: 50%;
        }
    }
    .btn-edit-photo {
        position: absolute;
        top: 0;
        right: 0;
        width: 32px;
        height: 32px;
        background-color: $azul;
        color: rgba($blanco, .6);
        text-align: center;
        line-height: 32px;
        transition: all .6s ease;
        &:hover {
            color: $blanco;
        }
    }
    ul {
        padding: 0;
        li {
            margin-bottom: 20px;
        }
        .dato {
            color: $morado;
            font-weight: bold;
            font-size: 2vmin;
            margin-bottom: 20px;
        }
        .info {
            font-size: 4vmin;
            color: $negroClaro;
            font-weight: bold;
            line-height: 1.4;
        }
        &.abajo {
            margin-top: 50px;
        }
    }
    // input[type='file'] {
    //     display: block;
    // }
    .acciones-cuenta-pass {
        display: block;
        width: 50%;
        @include tabletChica {
            width: 165px;
        }
    }
    .formulario-cambiar-password {
        transform: scale(0);
        transition: all .2s ease;
        &.mostrar-formulario-cambiar-password {
            transform: scale(1);
        }
    }
}

// BLOG DETALLE 
.controles-entrada-blog-detalle-grid {
    background-color: $morado;
    .boton {
        background-color: transparent;
        border: none;
        color: $blanco;
        text-align: left;
        flex: 1;
        padding: 20px 40px;
        &.izquierda {
            background-color: $morado2;
        }
        &.derecha {
            text-align: right;
            background-color: $morado3;
        }
        @include tabletGrande {
            padding: 65px 150px;
        }
        .titulo-entrada {
            color: $blanco;
            // width: 50%;
        }
    }
}
