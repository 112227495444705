@import '_vars';

.footer {
    background-color: $morado;
    padding-top: 40px;
    padding-bottom: 10px;
    color: rgba($blanco, .7);
    font-size: 3vmin;
    @include tabletChica {
        font-size: 1.6vmin; 
    }
    .container-footer {
        max-width: 1000px;
    }
    .titulo {
        font-size: 3vmin;
        color: $blanco;
        @include tabletChica {
            font-size: 1.6vmin;
        }
    }
    .columna-contactanos {
        border-left: 2px solid rgba($blanco, .4);
        min-height: 125px;
    }
    .columna-siguenos {
        border-left: 2px solid rgba($blanco, .4);
        min-height: 125px;
        .enlace {
            color: $blanco;
            font-size: 5vmin;
            @include tabletChica {
                font-size: 3vmin;
            }
        }
    }
    .politicas {
        .enlace {
            color: rgba($blanco, .7);
            border-bottom: none;
        }
    }
    .copy {
        color: rgba($blanco, .7);
        font-weight: 500;
        // font-size: 1.6vmin;
        line-height: 2.5;
        a{
            color: rgba($blanco, .7);
        }
    }
    .columna-siguenos {
        .enlace {
            border-bottom: none;
        }
    }
}
