@charset "UTF-8";
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #430326;
  height: 10px;
}

body {
  color: #333;
  font-family: 'Roboto', sans-serif;
}

a {
  color: #333;
}

a:hover {
  color: #333;
  text-decoration: none !important;
}

h1 {
  color: #72023F;
  font-weight: bold;
  font-size: 9vmin;
  font-family: 'Roboto Condensed', sans-serif;
}

h2 {
  color: #72023F;
  font-weight: bold;
  font-size: 9vmin;
  font-family: 'Roboto Condensed', sans-serif;
}

h3 {
  color: #083073;
  font-weight: bold;
  font-size: 5vmin;
  margin-bottom: 15px;
  font-family: 'Roboto Condensed', sans-serif;
}

@media (min-width: 768px) {
  h3 {
    margin-bottom: 30px;
  }
}

h4 {
  color: #083073;
  font-weight: bold;
  font-size: 2.6vmin;
  margin-bottom: 20px;
  font-family: 'Roboto Condensed', sans-serif;
}

h5 {
  color: #083073;
  font-weight: bold;
  font-size: 2.4vmin;
  margin-bottom: 10px;
  font-family: 'Roboto Condensed', sans-serif;
}

p {
  color: #808080;
  font-size: 3.2vmin;
  line-height: 1.7;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  p {
    font-size: 2vmin;
  }
}

ul {
  list-style: none;
}

label {
  cursor: pointer;
  user-select: none;
  font-family: 'Roboto Condensed', sans-serif;
}

button {
  box-shadow: none !important;
}

.contenedor-1240 {
  max-width: 1240px;
}

.contenedor-1000 {
  max-width: 1000px;
}

.contenedor-900 {
  max-width: 900px;
}

.texto-entrada-resumen {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.nav-link {
  color: rgba(255, 255, 255, 0.7);
  transition: all .4s ease;
  font-size: 3vmin;
  padding: 16px;
}

.nav-link.active {
  color: #FFF;
}

@media (min-width: 768px) {
  .nav-link {
    font-size: 1.6vmin;
    padding: 8px 16px;
  }
}

.nav-link:hover {
  color: #FFF;
}

.btn {
  outline: none;
  font-size: 3vmin;
  box-shadow: none !important;
}

.btn:active {
  background-color: #430326 !important;
  border-color: transparent !important;
}

@media (min-width: 768px) {
  .btn {
    font-size: 1.6vmin;
  }
}

.btn.btn-primary {
  background-color: #660037;
  color: rgba(255, 255, 255, 0.7);
  padding: 15px 20px;
  border-radius: 5px;
  border: none;
}

.btn.btn-primary.chico {
  padding: 10px 25px;
  color: #FFF;
}

.btn.btn-primary:hover {
  color: #FFF;
}

.btn.btn-secondary {
  background-color: #FFF;
  color: #430326;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-weight: bold;
}

.btn.btn-secondary:hover {
  color: #72023F;
}

.btn.btn-outline-primary {
  border-color: #72023F;
  color: #72023F;
  font-weight: bold;
  padding: 10px 20px;
}

.btn.btn-outline-primary svg {
  fill: #72023F;
}

.btn.btn-outline-primary.chico {
  padding: 5px 40px;
}

.btn.btn-outline-primary.grande {
  padding: 10px 40px;
}

.btn.btn-outline-primary:hover {
  background-color: #72023F;
  color: #FFF;
}

.btn.btn-outline-primary:hover svg {
  fill: #FFF;
}

.btn.btn-outline-secondary {
  border-color: #FFF;
  color: #FFF;
  font-weight: bold;
  padding: 10px 20px;
}

.btn.btn-outline-secondary:hover {
  background-color: #FFF;
  color: #72023F;
}

.borde-bottom-seccion {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.card {
  border: none;
}

.atomo-bg {
  background-image: url(../img/atomo-bg-hero.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 200px;
  height: 200px;
}

@media (min-width: 768px) {
  .atomo-bg {
    width: 350px;
    height: 350px;
  }
}

.enlace {
  color: rgba(255, 255, 255, 0.7);
  transition: all .6s ease;
  border-bottom: 1px solid transparent;
}

.enlace:hover {
  color: #FFF;
  border-bottom-color: #FFF;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0 !important;
}

@media (min-width: 768px) {
  .swiper-container-horizontal > .swiper-pagination-bullets {
    text-align: left !important;
  }
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  background: #72023F !important;
}

.icono-scroll {
  fill: #72023F;
}

.checkbox-position {
  position: relative;
}

.checkbox-position .circulo-icono-checkbox {
  position: absolute;
  width: 8px;
  height: 8px;
  line-height: 8px;
  background-color: #FFF;
  left: 4px;
  border-radius: 50%;
  display: none;
}

.checkbox-position input[type='checkbox'] {
  position: relative;
  width: 16px;
  height: 16px;
  line-height: 16px;
  appearance: none;
  outline: none;
}

.checkbox-position input[type='checkbox']::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  text-align: center;
  border: 2px solid #FFF;
  border-radius: 50%;
  color: #FFF;
  cursor: pointer;
}

.checkbox-position input[type='checkbox']:checked + .circulo-icono-checkbox {
  display: block;
}

.buscador {
  position: relative;
}

.buscador input[type='text'] {
  outline: none;
  border: none;
  border: 1px solid #72023F;
  padding: 5px 10px;
  border-radius: 5px;
}

.buscador .icono {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #72023F;
}

.video-fluid {
  width: 100%;
}

.swiper-button-next,
.swiper-button-prev {
  color: #000 !important;
  outline: none !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 16px !important;
}

.dropdown-menu {
  border-radius: 0 !important;
  border: none !important;
  padding: 10px 0 !important;
  min-width: unset !important;
  width: 190px;
  top: 5px !important;
  left: calc(50% - 190px / 2) !important;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .dropdown-menu {
    left: unset !important;
  }
}

.dropdown-menu .dropdown-item {
  color: #9f9fa1;
  font-weight: bold;
  font-size: 11px;
  padding: 10px 30px;
}

.modal .icono {
  color: #72023F;
}

.modal .lugar-fecha {
  color: #72023F;
  font-weight: bold;
}

.modal .close {
  color: #430326 !important;
}

.logo-iridian {
  fill: #FFF !important;
}

.dropdown-item:active {
  background-color: #72023F !important;
  color: #FFF;
}

.form-control {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.form-control:focus {
  box-shadow: 0 0 10px 0 rgba(114, 2, 63, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.categoria-fecha {
  color: #083073;
}

.autor {
  color: #083073;
}

.label-check {
  flex: 1;
}

.enlace-terminos-condiciones {
  color: #FFF;
  border-bottom: 1px solid #FFF;
}

.enlace-terminos-condiciones:hover {
  color: #FFF;
}

.titulo-categoria-linea .titulo {
  line-height: 1;
}

.titulo-categoria-linea::after {
  content: "";
  display: block;
  height: 2px;
  background-color: #72023F;
  flex: 1;
  margin-left: 10px;
  border-radius: 50px;
  align-self: flex-end;
}

.header {
  width: 100%;
  z-index: 90;
  transition: all .6s ease-in;
}

.header.ocultar {
  position: sticky;
  top: -90px;
  left: 0;
}

.header.mostrar {
  top: 0;
}

.menu-principal-header {
  background-color: rgba(114, 2, 63, 0.85);
  padding: 0 30px;
  font-size: 1.6vmin;
  font-weight: 600;
  color: #FFF;
  height: 65px;
}

.menu-principal-header .logo {
  width: 85px;
  margin-bottom: 5px;
}

.menu-principal-header .icono.hamburguesa {
  font-size: 24px;
  color: #FFF;
  position: absolute;
  right: 20px;
}

.menu-principal-header .icono.buscar {
  font-size: 16px;
  color: #FFF;
  cursor: pointer;
}

.menu-principal-header .icono.usuario {
  font-size: 16px;
  color: #FFF;
}

.menu-principal-header .nav {
  position: absolute;
  top: 65px;
  left: 0;
  background-color: rgba(114, 2, 63, 0.85);
  width: 100%;
  z-index: 90;
  transform: scale(0);
  display: block;
  transition: all .6s ease-in;
}

.menu-principal-header .nav.active {
  transform: scale(1);
  display: block;
}

@media (min-width: 992px) {
  .menu-principal-header .nav {
    transform: scale(1);
  }
}

@media (min-width: 992px) {
  .menu-principal-header .nav {
    position: unset;
    width: auto;
    background-color: unset;
    margin-left: 40px;
  }
}

.menu-principal-header .buscador-idioma-usuario-grid {
  position: absolute;
  right: 60px;
}

.menu-principal-header .buscador-idioma-usuario-grid .expandir-opciones-idioma-icono {
  fill: #FFF;
}

.menu-principal-header .select-idiomas {
  background-color: transparent;
  outline: none;
  border: none;
  color: #FFF;
}

.menu-principal-header .select-idiomas option {
  color: #333;
}

.hero {
  position: relative;
}

.hero .swiper-slide .imagen {
  width: 100%;
  height: 30vw;
  object-fit: cover;
}

.hero.hero-corto {
  height: 300px;
  background-position: center bottom;
}

.hero.foro-individual {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 35%, rgba(0, 0, 0, 0.4) 100%), url(../img/banner-quimica-mujer.png);
  height: 400px;
}

.hero.foro-individual .fila-contenido {
  height: calc(90%);
}

.hero.foro-individual p, .hero.foro-individual h3, .hero.foro-individual .autor {
  color: #FFF;
}

.hero.foro-individual h3 {
  margin-bottom: 0;
}

.hero .carousel-grid {
  height: calc(100% - 65px);
  transition: all 1s ease-in;
}

.hero .parrafo-carousel-hero {
  font-size: 4vmin;
  margin-top: 40px;
  color: #333;
}

.hero .contenedor-icono-scroll {
  position: absolute;
  padding-bottom: 64px;
  z-index: 10;
}

.hero .contenedor-icono-scroll .icono-scroll {
  position: relative;
  transition: all 1s ease-in;
}

.hero .contenedor-icono-scroll .icono-scroll.bajar {
  top: 65px;
}

.hero .contenedor-icono-scroll .icono-scroll.subir {
  top: 0;
}

.hero .atomo-bg-hero {
  background-image: url(../img/atomo-bg-hero.png);
  width: 200px;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -20px;
  right: 0;
  z-index: 1;
}

@media (min-width: 768px) {
  .hero .atomo-bg-hero {
    width: 350px;
    height: 350px;
  }
}

.redes-sociales-fixed {
  position: fixed;
  top: 50%;
  left: 2%;
  text-align: center;
  z-index: 90;
}

.redes-sociales-fixed .icono.redes-sociales {
  display: block;
  width: 100%;
  color: #660037;
  margin-bottom: 20px;
  font-size: 5vmin;
}

@media (min-width: 768px) {
  .redes-sociales-fixed .icono.redes-sociales {
    font-size: 3vmin;
    margin-bottom: 15px;
  }
}

.redes-sociales-fixed .icono.redes-sociales.webinar {
  color: #FFF !important;
}

.escribenos-fixed {
  position: fixed;
  right: 4%;
  top: 90%;
  z-index: 90;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4);
}

.escribenos-fixed .btn {
  transition: all .6s ease;
}

.escribenos-fixed .btn.btn-primary.change-color {
  background-color: #FFF;
  color: #72023F;
}

.escribenos-fixed .btn.btn-primary.change-color .icono {
  fill: #72023F;
}

.escribenos-fixed .icono {
  fill: #FFF;
}

.buscador-general {
  position: fixed;
  top: 65px;
  right: 0;
  z-index: 90;
  width: 300px;
  padding: 20px;
  background-color: #FFF;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  transform: scale(0);
  transition: all .2s ease-in-out;
}

.buscador-general.arriba {
  top: 0;
}

.buscador-general.abajo {
  top: 65px;
}

.buscador-general.active {
  transform: scale(1);
}

.buscador-general input {
  letter-spacing: 2px;
}

@media (min-width: 768px) {
  .actualidad {
    padding-top: 90px;
  }
}

.actualidad .boton-filtrar.active {
  background-color: #72023F !important;
  border-color: transparent !important;
}

.actualidad .boton-filtrar.active svg {
  fill: #FFF !important;
}

.actualidad .filtro-grande {
  display: none;
}

.actualidad .filtro-grande.active {
  display: block;
}

.actualidad .filtro-grande select {
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  flex: 3;
  color: #808080;
  font-size: 12px;
  padding-bottom: 10px;
  font-weight: bold;
  outline: none;
}

.actualidad .filtro-grande.columna-borde {
  border-top: 2px solid rgba(0, 0, 0, 0.2);
}

.actualidad .filtro-grande .titulos-filtros {
  flex: 1;
}

.actualidad .filtro-grande .titulos-filtros span {
  display: block;
  margin-bottom: 0;
  color: #808080;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 20px;
}

.actualidad .filtro-grande .filtro-singular {
  margin-bottom: 20px;
}

.actualidad .contenedor-actualidad {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.actualidad .columna-borde {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.actualidad .entrada-individual {
  flex: 0 0 calc(50% - 20px);
  margin-bottom: 40px;
}

.actualidad .atomo-bg.actualidad {
  position: absolute;
  top: 275px;
  right: 0;
}

.webinar .contenedor-webinar {
  max-width: 1240px;
}

.webinar .fila {
  background-color: #72023F;
}

.webinar .fila .titulo-interno {
  color: #FFF;
}

.webinar .fila .parrafo-interno {
  color: rgba(255, 255, 255, 0.8);
}

.webinar .fila .parrafo-interno.duracion {
  margin-top: 10px;
  font-weight: bold;
}

.webinar .columna-izquierda {
  color: #FFF;
}

.webinar .icono.play {
  fill: #FFF;
}

.webinar .btn.video-principal:hover .icono.play {
  fill: #72023F;
}

.webinar .columna-derecha .video {
  width: 100%;
  height: calc(100% + 40px);
}

@media (min-width: 768px) {
  .webinar .columna-derecha {
    position: relative;
  }
  .webinar .columna-derecha .video {
    position: absolute;
    top: -20px;
    width: 90%;
  }
}

.blog-foros .cards-blog .card {
  flex: 0 0 calc(50% - 5px);
}

@media (min-width: 768px) {
  .blog-foros .cards-blog .card {
    flex: 0 0 calc(50% - 20px);
  }
}

.cta {
  background-color: #f3f3f3;
  padding: 60px 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (min-width: 1200px) {
  .cta {
    background-image: url(../img/cta-newsletter-biospifar.png);
  }
}

.cta form input[type='email'] {
  display: block;
  width: 100%;
}

.cta button {
  display: block;
  width: 100%;
}

.proximo-evento .columna-derecha {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

/***********************PÁGINAS INTERNAS**************************/
.mision-vision {
  position: relative;
}

.mision-vision .titulo {
  font-size: 5vmin;
}

.mision-vision .atomo-bg.mision-vision {
  position: absolute;
  top: 0;
  right: 0;
}

.alidos-estrategicos {
  position: relative;
}

.alidos-estrategicos .galeria .thumb-galeria {
  flex: 0 0 calc(20%);
  margin-bottom: 60px;
}

.alidos-estrategicos .atomo-bg.aliados-estrategicos {
  position: absolute;
  bottom: -40px;
  right: 0;
}

.trabaje-con-nosotros {
  background-image: url(../img/ingeniera-bg-biospifar-registro.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  position: relative;
}

.trabaje-con-nosotros .titulo {
  flex: 0 0 25%;
}

@media (min-width: 768px) {
  .trabaje-con-nosotros .formulario {
    min-width: 300px;
  }
}

.trabaje-con-nosotros .contenedor-formulario {
  background-image: linear-gradient(180deg, #72023f 0%, #660037 35%, #430326 100%);
  border-radius: 10px;
  font-size: 2.8vmin;
  color: #FFF;
}

@media (min-width: 768px) {
  .trabaje-con-nosotros .contenedor-formulario {
    font-size: 2vmin;
  }
}

.trabaje-con-nosotros .contenedor-formulario .input-group {
  position: relative;
  margin-bottom: 40px;
}

.trabaje-con-nosotros .contenedor-formulario input[type='text'],
.trabaje-con-nosotros .contenedor-formulario input[type='tel'],
.trabaje-con-nosotros .contenedor-formulario input[type='email'] {
  display: block;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  color: #FFF;
  transition: all .6s ease;
}

.trabaje-con-nosotros .contenedor-formulario input[type='text']:focus,
.trabaje-con-nosotros .contenedor-formulario input[type='tel']:focus,
.trabaje-con-nosotros .contenedor-formulario input[type='email']:focus {
  border-bottom: 2px solid rgba(255, 255, 255, 0.4);
}

.trabaje-con-nosotros .contenedor-formulario input[type='file'] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.trabaje-con-nosotros .contenedor-formulario .label {
  position: absolute;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.7);
  cursor: text;
  transition: all .6s ease;
}

.trabaje-con-nosotros .contenedor-formulario .label.active {
  top: -20px;
  font-size: 2.4vmin;
}

@media (min-width: 768px) {
  .trabaje-con-nosotros .contenedor-formulario .label.active {
    font-size: 1.6vmin;
  }
}

.trabaje-con-nosotros .atomo-bg.trabaje {
  position: absolute;
  bottom: 40px;
  right: 0;
}

.webinar.pagina-interna {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 90px;
  position: relative;
  z-index: 0;
}

.webinar.pagina-interna::before {
  content: "";
  background-image: linear-gradient(90deg, rgba(153, 96, 129, 0.9) 0%, rgba(153, 96, 129, 0.9) 35%, rgba(153, 96, 129, 0.9) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
}

.webinar.pagina-interna .titulo {
  color: #FFF;
}

.webinar.pagina-interna .parrafo-interno.duracion {
  margin-top: 10px;
  font-weight: bold;
}

.webinar.pagina-interna .fila {
  background-color: rgba(114, 2, 63, 0.6);
  min-height: 450px;
}

@media (min-width: 768px) {
  .webinar.pagina-interna .fila .carousel-grid-video {
    position: relative;
    top: -90px;
  }
}

.webinar.pagina-interna .fila .carousel-grid-video .video-singular {
  background-color: #FFF;
  padding: 15px;
  border-radius: 5px;
}

.webinar.pagina-interna .fila .carousel-grid-video .video-singular .video-fluid {
  height: 200px;
}

.webinar.pagina-interna .fila .carousel-grid-video .video-singular .parrafo-interno.duracion {
  color: #333;
}

.webinar.pagina-interna .fila .controles-grid {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .webinar.pagina-interna .fila .controles-grid {
    position: relative;
    top: -60px;
    margin-top: 0;
  }
}

.webinar.pagina-interna .swiper-scrollbar {
  width: 33%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.4);
}

@media (min-width: 992px) {
  .webinar.pagina-interna .swiper-scrollbar {
    width: 60%;
  }
}

.webinar.pagina-interna .swiper-scrollbar .swiper-scrollbar-drag {
  background-color: #996081;
}

.webinar.pagina-interna .flechas {
  color: #FFF;
  user-select: none;
  outline: none !important;
}

.webinar.pagina-interna .flechas .icono {
  margin: 0 20px;
  font-size: 16px;
  outline: none !important;
}

.productos {
  position: relative;
}

.productos .opcion-filtro {
  width: calc(50% - 8px);
  transition: all .6s ease;
}

@media (min-width: 768px) {
  .productos .opcion-filtro {
    width: 120px;
  }
}

@media (min-width: 992px) {
  .productos .opcion-filtro {
    width: 165px;
  }
}

.productos input[type='radio'] {
  display: none;
}

.productos input[type='radio']:checked + label {
  background-color: #72023F;
  color: #FFF;
}

.productos .productos-grid .producto-singular {
  flex: 0 0 calc(50% - 10px);
  margin-bottom: 50px;
}

@media (min-width: 768px) {
  .productos .productos-grid .producto-singular {
    flex: 0 0 calc(33.33% - 10px);
  }
}

@media (min-width: 992px) {
  .productos .productos-grid .producto-singular {
    flex: 0 0 calc(25% - 10px);
  }
}

.productos .atomo-bg.productos {
  position: absolute;
  right: 0;
  top: 200px;
}

.producto-singular {
  position: relative;
}

.producto-singular .producto-singular-grid .producto-singular {
  flex: 0 0 calc(50%);
  margin-bottom: 50px;
}

.producto-singular .opcion-filtro {
  width: calc(50% - 8px);
  transition: all .6s ease;
}

@media (min-width: 768px) {
  .producto-singular .opcion-filtro {
    width: 120px;
  }
}

@media (min-width: 992px) {
  .producto-singular .opcion-filtro {
    width: 165px;
  }
}

.producto-singular input[type='radio'] {
  display: none;
}

.producto-singular input[type='radio']:checked + label {
  background-color: #72023F;
  color: #FFF;
}

.producto-singular .columna-derecha {
  background-color: #f3f3f3;
}

.producto-singular .atomo-bg.producto {
  position: absolute;
  top: 200px;
  right: 0;
}

.login-registro {
  background-image: url(../img/ingeniera-bg-biospifar-registro.jpg);
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  min-height: 100vh;
}

@media (min-width: 768px) {
  .login-registro .formulario {
    min-width: 300px;
    max-width: 400px;
  }
}

.login-registro .formulario-contenedor {
  background-image: linear-gradient(180deg, #72023f 0%, #660037 35%, #430326 100%);
  border-radius: 10px;
  font-size: 2.8vmin;
  color: #FFF;
}

@media (min-width: 768px) {
  .login-registro .formulario-contenedor {
    font-size: 2vmin;
  }
}

.login-registro .formulario-contenedor .enlace-interno-grande {
  font-size: 3vmin;
  color: rgba(255, 255, 255, 0.7);
}

@media (min-width: 768px) {
  .login-registro .formulario-contenedor .enlace-interno-grande {
    font-size: 2.6vmin;
  }
}

.login-registro .formulario-contenedor .enlace-interno-grande:hover {
  color: #FFF;
}

.login-registro .formulario-contenedor .enlace-interno-grande.active {
  color: #FF9ED2;
  border-bottom: 1px solid #FF9ED2;
  font-weight: bold;
}

.login-registro .formulario-contenedor .input-group {
  position: relative;
  margin-bottom: 40px;
}

.login-registro .formulario-contenedor input[type='text'],
.login-registro .formulario-contenedor input[type='email'],
.login-registro .formulario-contenedor input[type='tel'],
.login-registro .formulario-contenedor input[type='password'] {
  display: block;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  color: #FFF;
  transition: all .6s ease;
}

.login-registro .formulario-contenedor input[type='text']:focus,
.login-registro .formulario-contenedor input[type='email']:focus,
.login-registro .formulario-contenedor input[type='tel']:focus,
.login-registro .formulario-contenedor input[type='password']:focus {
  border-bottom: 2px solid rgba(255, 255, 255, 0.4);
}

.login-registro .formulario-contenedor .label {
  position: absolute;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.7);
  cursor: text;
  transition: all .6s ease;
}

.login-registro .formulario-contenedor .label.active {
  top: -20px;
  font-size: 2.4vmin;
}

@media (min-width: 768px) {
  .login-registro .formulario-contenedor .label.active {
    font-size: 1.6vmin;
  }
}

.login-registro .atomo-bg.registro-login {
  position: absolute;
  bottom: -90px;
  right: 0;
}

.historia {
  background-color: #f3f3f3;
}

.historia .titulo-historia {
  color: #083073 !important;
}

.historia .historia-grid .columna.izquierda {
  flex: 1;
  background-color: #FFF;
}

@media (min-width: 1200px) {
  .historia .historia-grid .columna.izquierda {
    flex: 0 0 calc(40% - 40px);
  }
}

.historia .historia-grid .columna.derecha {
  background-color: #FFF;
  flex: 0 0 50%;
}

@media (min-width: 1200px) {
  .historia .historia-grid .columna.derecha {
    display: block;
    flex: 0 0 calc(60% - 40px);
  }
}

.historia .historia-grid .columna.derecha .logo {
  width: 100%;
}

@media (min-width: 768px) {
  .historia .historia-grid .columna.derecha .logo {
    width: 350px;
  }
}

@media (min-width: 1200px) {
  .historia .historia-grid .columna.derecha .logo {
    position: relative;
    left: -40px;
  }
}

.historia .historia-grid .columna.derecha .atomo-bg.linea-tiempo {
  position: absolute;
  top: 50%;
  right: 0;
}

.historia .historia-grid .timeline {
  position: relative;
  left: -50%;
}

@media (min-width: 1200px) {
  .historia .historia-grid .timeline {
    left: calc(-55% - 2px);
  }
}

.historia .historia-grid .timeline .fechas {
  padding: 0;
}

.historia .historia-grid .timeline .fechas .fecha-entrada-singular {
  position: relative;
  margin-top: 60px;
}

.historia .historia-grid .timeline .fechas .fecha-entrada-singular:first-of-type {
  margin-top: 90px;
}

.historia .historia-grid .timeline .fechas .fecha-entrada-singular::before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  border: 2px dashed #660037;
  height: 100%;
}

.historia .historia-grid .timeline .fechas .fecha-entrada-singular:first-of-type::before {
  border-color: #72023F;
}

.historia .historia-grid .timeline .fechas .fecha-entrada-singular:last-of-type::before {
  border-color: #430326;
}

.historia .historia-grid .timeline .fechas .fecha-entrada-singular .fecha {
  font-size: 4vmin;
  color: #660037;
  font-weight: bold;
  text-align: center;
  width: 100%;
  display: block;
  position: relative;
  top: -40px;
}

.historia .historia-grid .timeline .fechas .fecha-entrada-singular:first-of-type .fecha {
  color: #72023F;
}

.historia .historia-grid .timeline .fechas .fecha-entrada-singular:last-of-type .fecha {
  color: #430326;
}

.historia .historia-grid .timeline .fechas .fecha-entrada-singular .historias-interiores {
  padding: 0;
}

.historia .historia-grid .timeline .fechas .fecha-entrada-singular .historias-interiores .historia-interior-singular {
  width: 80%;
}

@media (min-width: 768px) {
  .historia .historia-grid .timeline .fechas .fecha-entrada-singular .historias-interiores .historia-interior-singular {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .historia .historia-grid .timeline .fechas .fecha-entrada-singular .historias-interiores .historia-interior-singular {
    margin-bottom: 120px;
  }
}

.historia .historia-grid .timeline .fechas .fecha-entrada-singular .historias-interiores .historia-interior-singular .textos {
  position: relative;
  padding: 20px 0;
}

.historia .historia-grid .timeline .fechas .fecha-entrada-singular .historias-interiores .historia-interior-singular .imagen {
  position: absolute;
  top: 30%;
  width: 100%;
}

@media (min-width: 1200px) {
  .historia .historia-grid .timeline .fechas .fecha-entrada-singular .historias-interiores .historia-interior-singular .imagen {
    top: 0;
  }
}

.historia .historia-grid .timeline .fechas .fecha-entrada-singular .historias-interiores .historia-interior-singular .imagen img {
  width: 100%;
}

.historia .historia-grid .timeline .fechas .fecha-entrada-singular .historias-interiores .historia-interior-singular:nth-child(odd) {
  float: right;
  text-align: left;
  clear: both;
  position: relative;
}

.historia .historia-grid .timeline .fechas .fecha-entrada-singular .historias-interiores .historia-interior-singular:nth-child(odd) .textos {
  left: 50%;
}

@media (min-width: 768px) {
  .historia .historia-grid .timeline .fechas .fecha-entrada-singular .historias-interiores .historia-interior-singular:nth-child(odd) .textos {
    left: 20%;
  }
}

.historia .historia-grid .timeline .fechas .fecha-entrada-singular .historias-interiores .historia-interior-singular:nth-child(odd) .imagen {
  right: 62%;
}

@media (min-width: 768px) {
  .historia .historia-grid .timeline .fechas .fecha-entrada-singular .historias-interiores .historia-interior-singular:nth-child(odd) .imagen {
    right: 100%;
  }
}

.historia .historia-grid .timeline .fechas .fecha-entrada-singular .historias-interiores .historia-interior-singular:nth-child(even) {
  float: left;
  text-align: right;
  clear: both;
  position: relative;
}

.historia .historia-grid .timeline .fechas .fecha-entrada-singular .historias-interiores .historia-interior-singular:nth-child(even) .textos {
  right: 50%;
}

@media (min-width: 768px) {
  .historia .historia-grid .timeline .fechas .fecha-entrada-singular .historias-interiores .historia-interior-singular:nth-child(even) .textos {
    right: 20%;
  }
}

.historia .historia-grid .timeline .fechas .fecha-entrada-singular .historias-interiores .historia-interior-singular:nth-child(even) .imagen {
  left: calc(62% + 4px);
}

@media (min-width: 768px) {
  .historia .historia-grid .timeline .fechas .fecha-entrada-singular .historias-interiores .historia-interior-singular:nth-child(even) .imagen {
    left: calc(100% + 4px);
  }
}

.historia .historia-grid .timeline .fechas .fecha-entrada-singular .historias-interiores .frase-destacada {
  color: #083073;
  font-weight: bold;
  width: 25%;
  position: absolute;
  right: -40%;
  top: calc(50% - 70px);
  display: none;
}

@media (min-width: 1200px) {
  .historia .historia-grid .timeline .fechas .fecha-entrada-singular .historias-interiores .frase-destacada {
    display: block;
  }
}

.investigacion {
  position: relative;
}

.investigacion .tarjetas-arriba .card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  flex: 0 0 calc(50% - 10px);
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .investigacion .tarjetas-arriba .card {
    flex: 0 0 calc(20% - 10px);
  }
}

.investigacion .tarjetas-arriba .card .card-body {
  padding: 10px 0;
}

.investigacion .tarjetas-arriba .card .card-body .card-title {
  margin-bottom: 0;
}

.investigacion .tarjetas-abajo .card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .investigacion .tarjetas-abajo .card {
    flex: 0 0 calc(33.33% - 10px);
  }
}

.investigacion .tarjetas-abajo .card .btn.btn-outline-primary {
  padding: 5px 20px;
  margin-top: 20px;
}

.investigacion .atomo-bg.investigacion {
  position: absolute;
  top: 200px;
  right: 0;
}

.foros {
  position: relative;
}

.foros .foro-entrada {
  padding: 40px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.foros .foro-entrada .contenido-foro-entrada {
  width: 100%;
}

@media (min-width: 768px) {
  .foros .foro-entrada .contenido-foro-entrada {
    width: 70%;
  }
}

.foros .atomo-bg.foros {
  position: absolute;
  top: 90px;
  right: 0;
  z-index: -10;
}

.blog {
  position: relative;
}

@media (min-width: 768px) {
  .blog .contenedor-titulo-buscador {
    width: calc(50% + 53px);
  }
}

.blog .tarjetas .card.small {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-right: 10px;
  text-align: center;
  margin-bottom: 20px;
}

.blog .tarjetas .card.small .card-body {
  padding: 10px 0;
}

.blog .tarjetas .card.small .card-body .card-title {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .blog .fila-abajo {
    margin-top: -60px;
  }
}

.blog .atomo-bg.blog {
  position: absolute;
  top: 200px;
  right: 0;
}

@media (min-width: 768px) {
  .actualidad-blog .cards-blog .card {
    flex: 0 0 calc(33.33% - 20px);
  }
}

.calendario {
  position: relative;
}

.calendario .calendario-contenedor {
  background-color: rgba(236, 236, 236, 0.6);
}

.calendario .fc-button {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: #72023F !important;
}

.calendario .fc-button .fc-icon {
  font-size: 64px !important;
}

.calendario .fc-toolbar.fc-header-toolbar {
  background-color: #FFF !important;
  padding-left: 0 !important;
}

@media (min-width: 992px) {
  .calendario .fc-toolbar.fc-header-toolbar {
    padding-right: 500px;
  }
}

.calendario .fc-toolbar-title {
  font-size: 9vmin !important;
}

.calendario .fc-scrollgrid {
  border: none !important;
}

.calendario .fc-col-header-cell-cushion {
  color: #72023F !important;
  padding-left: 20px !important;
  font-size: 4vmin;
}

.calendario .fc-scrollgrid-sync-inner {
  text-align: left !important;
}

.calendario .fc-theme-standard td, .calendario .fc-theme-standard th {
  border: none !important;
}

.calendario .fc-daygrid-day-top {
  display: unset !important;
  font-weight: bold;
}

.calendario .fc-daygrid-day-top .fc-daygrid-day-number {
  color: #525252 !important;
  font-size: 2.4vmin;
}

.calendario .fc-day-today {
  background-color: #660037 !important;
}

.calendario .fc-day-today .fc-daygrid-day-number {
  color: #FFF !important;
}

.calendario .fc-day-today .fc-event-title {
  color: rgba(255, 255, 255, 0.8);
  overflow: unset !important;
}

.calendario .fc-daygrid-event {
  white-space: normal !important;
}

.calendario .fc-h-event {
  background: none !important;
  border: none !important;
}

.calendario .fc-event-title {
  color: #525252;
  font-size: 2.2vmin;
  font-weight: bold;
}

.calendario .atomo-bg {
  position: absolute;
}

.calendario .atomo-bg.calendario-eventos {
  top: 120px;
  right: 0;
}

.crear-foro {
  background-image: url(../img/ingeniera-bg-biospifar-registro.jpg);
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  min-height: 100vh;
  position: relative;
}

.crear-foro .formulario label {
  color: #083073;
  font-weight: bold;
  margin-bottom: 15px;
}

.crear-foro .formulario input[type='text']
textarea {
  outline: none;
}

.crear-foro .formulario textarea {
  display: block;
  width: 100%;
  min-height: 100px;
  max-height: 200px;
}

.crear-foro .formulario input[type='file'] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.crear-foro .atomo-bg.foro-crear {
  position: absolute;
  bottom: -20px;
  right: 0;
}

.foro-blog-entrada-singular {
  position: relative;
  background-color: #fbfbfb;
}

.foro-blog-entrada-singular .autor {
  color: #083073;
  font-weight: bold;
}

.foro-blog-entrada-singular .columna-borde {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.foro-blog-entrada-singular p {
  margin-bottom: 20px;
}

.foro-blog-entrada-singular textarea {
  min-height: 100px;
  max-height: 200px;
}

.foro-blog-entrada-singular .entrada-comentario-individual .foto-perfil {
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.foro-blog-entrada-singular .comentarios .comentario-principal {
  padding-left: 0;
}

.foro-blog-entrada-singular .comentarios .entrada-comentario {
  padding-bottom: 20px;
}

.foro-blog-entrada-singular .comentarios .icono-like-border {
  fill: #083073;
  cursor: pointer;
}

.foro-blog-entrada-singular .comentarios .icono-like-border.add-like-bg {
  background-color: #083073;
  fill: #FFF;
  border-radius: 50%;
}

.foro-blog-entrada-singular .comentarios .icono-like-border.remove-like-border {
  display: none;
}

.foro-blog-entrada-singular .comentarios .icono-like-paint {
  fill: #083073;
  cursor: pointer;
  display: none;
}

.foro-blog-entrada-singular .comentarios .icono-like-paint.show-icono-like-paint {
  display: block;
}

.foro-blog-entrada-singular .comentarios .icono-comentarios {
  fill: #083073;
}

.foro-blog-entrada-singular .comentarios .numero-likes {
  color: #083073;
  font-weight: bold;
  font-size: 10px;
}

.foro-blog-entrada-singular .comentarios .numero-comentarios {
  color: #083073;
  font-weight: bold;
}

.foro-blog-entrada-singular .comentarios .numero-respuestas {
  color: #083073;
  font-weight: bold;
  font-size: 10px;
}

.foro-blog-entrada-singular .comentarios .nombre-comenta {
  color: #083073;
  font-weight: bold;
  font-size: 12px;
}

.foro-blog-entrada-singular .comentarios .tiempo-comenta {
  color: #083073;
  font-size: 12px;
}

.foro-blog-entrada-singular .comentarios .redes-sociales a {
  color: #083073;
}

.foro-blog-entrada-singular .atomo-bg.detalle-blog {
  position: absolute;
  top: 0;
  right: 0;
}

.lista-videos {
  position: relative;
}

.lista-videos .columna-lista-videos {
  max-height: 600px;
  overflow-y: scroll;
}

.lista-videos .contenedor-video {
  max-width: 250px;
}

.lista-videos .video-grande {
  height: 350px;
}

.lista-videos .atomo-bg.videos-lista {
  position: absolute;
  bottom: 0;
  right: 0;
}

.investigacion-singular {
  position: relative;
}

.investigacion-singular .otras-investigaciones {
  margin-top: 80px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.investigacion-singular .otras-investigaciones .otras-investigaciones-grid .investigacion-singular {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 25px;
  border-radius: 5px;
}

.investigacion-singular .atomo-bg.singular-investigacion {
  position: absolute;
  top: 300px;
  right: 0;
}

.webinar-videos .webinar-videos-grid .card {
  flex: 0 0 calc(33.33% - 20px);
  margin-bottom: 50px;
}

.webinar-videos .webinar-videos-grid .card .video-fluid {
  height: 200px;
}

.webinar-videos .webinar-videos-grid .card .parrafo-interno.duracion {
  font-weight: bold;
}

.webinar-videos .boton-filtrar.active {
  background-color: #72023F !important;
  border-color: transparent !important;
}

.webinar-videos .boton-filtrar.active svg {
  fill: #FFF !important;
}

.webinar-videos .filtro-grande {
  display: none;
}

.webinar-videos .filtro-grande.active {
  display: block;
}

.webinar-videos .filtro-grande.columna-borde {
  border-top: 2px solid rgba(0, 0, 0, 0.2);
}

.webinar-videos .filtro-grande select {
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  flex: 2;
  color: #808080;
  font-size: 12px;
  padding-bottom: 10px;
  font-weight: bold;
  outline: none;
}

.webinar-videos .filtro-grande select:last-of-type {
  margin-left: 40px;
}

.webinar-videos .filtro-grande .titulos-filtros {
  flex: 1;
}

.webinar-videos .filtro-grande .titulos-filtros span {
  display: block;
  margin-bottom: 0;
  color: #808080;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 20px;
}

.webinar-videos .atomo-bg.videos-webinar {
  position: absolute;
  top: 300px;
  right: 0;
}

.perfil-usuario {
  background-image: url(../img/bg-wave-hero.png);
  background-size: cover;
  background-position: -90px bottom;
  background-repeat: no-repeat;
  min-height: 100vh;
}

@media (min-width: 768px) {
  .perfil-usuario {
    background-size: contain;
  }
}

.perfil-usuario input[type='file'] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.perfil-usuario .imagen-perfil {
  position: relative;
}

@media (min-width: 768px) {
  .perfil-usuario .imagen-perfil {
    width: 50%;
  }
}

.perfil-usuario .btn-edit-photo {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  background-color: #083073;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  line-height: 32px;
  transition: all .6s ease;
}

.perfil-usuario .btn-edit-photo:hover {
  color: #FFF;
}

.perfil-usuario ul {
  padding: 0;
}

.perfil-usuario ul li {
  margin-bottom: 20px;
}

.perfil-usuario ul .dato {
  color: #72023F;
  font-weight: bold;
  font-size: 2vmin;
  margin-bottom: 20px;
}

.perfil-usuario ul .info {
  font-size: 4vmin;
  color: #333;
  font-weight: bold;
  line-height: 1.4;
}

.perfil-usuario ul.abajo {
  margin-top: 50px;
}

.perfil-usuario .acciones-cuenta-pass {
  display: block;
  width: 50%;
}

@media (min-width: 768px) {
  .perfil-usuario .acciones-cuenta-pass {
    width: 165px;
  }
}

.perfil-usuario .formulario-cambiar-password {
  transform: scale(0);
  transition: all .2s ease;
}

.perfil-usuario .formulario-cambiar-password.mostrar-formulario-cambiar-password {
  transform: scale(1);
}

.controles-entrada-blog-detalle-grid {
  background-color: #72023F;
}

.controles-entrada-blog-detalle-grid .boton {
  background-color: transparent;
  border: none;
  color: #FFF;
  text-align: left;
  flex: 1;
  padding: 20px 40px;
}

.controles-entrada-blog-detalle-grid .boton.izquierda {
  background-color: #660037;
}

.controles-entrada-blog-detalle-grid .boton.derecha {
  text-align: right;
  background-color: #430326;
}

@media (min-width: 992px) {
  .controles-entrada-blog-detalle-grid .boton {
    padding: 65px 150px;
  }
}

.controles-entrada-blog-detalle-grid .boton .titulo-entrada {
  color: #FFF;
}

.footer {
  background-color: #72023F;
  padding-top: 40px;
  padding-bottom: 10px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 3vmin;
}

@media (min-width: 768px) {
  .footer {
    font-size: 1.6vmin;
  }
}

.footer .container-footer {
  max-width: 1000px;
}

.footer .titulo {
  font-size: 3vmin;
  color: #FFF;
}

@media (min-width: 768px) {
  .footer .titulo {
    font-size: 1.6vmin;
  }
}

.footer .columna-contactanos {
  border-left: 2px solid rgba(255, 255, 255, 0.4);
  min-height: 125px;
}

.footer .columna-siguenos {
  border-left: 2px solid rgba(255, 255, 255, 0.4);
  min-height: 125px;
}

.footer .columna-siguenos .enlace {
  color: #FFF;
  font-size: 5vmin;
}

@media (min-width: 768px) {
  .footer .columna-siguenos .enlace {
    font-size: 3vmin;
  }
}

.footer .politicas .enlace {
  color: rgba(255, 255, 255, 0.7);
  border-bottom: none;
}

.footer .copy {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  line-height: 2.5;
}

.footer .copy a {
  color: rgba(255, 255, 255, 0.7);
}

.footer .columna-siguenos .enlace {
  border-bottom: none;
}
