@mixin soloMobile {
  @media (max-width: 768px) { 
      @content; 
  }
}
@mixin tabletChica {
  @media (min-width: 768px) { 
      @content; 
  }
}
@mixin tabletGrande {
  @media (min-width: 992px) { 
      @content; 
  }
}
@mixin laptop {
  @media (min-width: 1200px) { 
      @content; 
  }
}
@mixin escritorio {
  @media (min-width: 1400px) { 
      @content; 
  }
}