// COLORES
$morado: #72023F;
$morado2: #660037;
$morado3: #430326;
$azul: #083073;
$rosa: #FF9ED2;
$moradoClaro: #996081;
$blanco: #FFF;
$blanco2: #f3f3f3;
$blanco3: #ececec;
$blanco4: #fbfbfb;
$negroPuro: #000;
$negroClaro: #333;
$grisOscuro: #525252;
$gris: #808080;
$gris2: #9f9fa1;

// BORDES
$radius-5: 5px;
$radius-10: 10px;
$radius-50: 50%;
$borde-seccion: 1px solid rgba($negroPuro, .1);
$borde-seccion-2: 2px solid rgba($negroPuro, .2);

// Z-INDEX
$index-90: 90;
$index-50: 50;
$index-20: 20;
$index-10: 10;
$index-0: 0;
$index--10: -10;