@import '_vars';
.header {
    width: 100%;
    z-index: $index-90;
    transition: all .6s ease-in;
    &.ocultar {
        position: sticky;
        top: -90px;
        left: 0;
    }
    &.mostrar {
        top: 0;
    }
}
.menu-principal-header {
    background-color: rgba($morado, .85);
    padding: 0 30px;
    font-size: 1.6vmin;
    font-weight: 600;
    color: $blanco;
    height: 65px;
    .logo {
        width: 85px;
        margin-bottom: 5px;
    }
    .icono {
        &.hamburguesa {
            font-size: 24px;
            color: $blanco;
            position: absolute;
            right: 20px;
        }
        &.buscar {
            font-size: 16px;
            color: $blanco;
            cursor: pointer;
        }
        &.usuario {
            font-size: 16px;
            color: $blanco;
        }
    }
    .nav {
        position: absolute;
        top: 65px;
        left: 0;
        background-color: rgba($morado, .85);
        width: 100%;
        z-index: $index-90;
        transform: scale(0);
        display: block;
        transition: all .6s ease-in;
        &.active {
            transform: scale(1);
            display: block;
        }
        @include tabletGrande {
            transform: scale(1);
        }
        @include tabletGrande {
            position: unset;
            width: auto;
            background-color: unset;
            margin-left: 40px;
        }
    }
    .buscador-idioma-usuario-grid {
        position: absolute;
        right: 60px;
        .expandir-opciones-idioma-icono {
            fill: $blanco;
        }
    }
    .select-idiomas {
        background-color: transparent;
        outline: none;
        border: none;
        color: $blanco;
        option {
            color: $negroClaro;
        }
    }
}
.hero {
    // background-image: url(../img/ingeniera-hero-biospifar2.jpg);
    // height: 30vw;
    // background-size: cover;
    // background-position: center;
    // background-repeat: no-repeat;
    // transition: all 1s ease;
    position: relative;
    .swiper-slide {
        .imagen {
            width: 100%; 
            height: 30vw;  
            object-fit: cover;
        }
    }
    &.hero-corto {
        height: 300px;
        background-position: center bottom;
    }
    &.foro-individual {
        background-image: linear-gradient(90deg, rgba(0,0,0,.4) 0%, rgba(0,0,0,.4) 35%, rgba(0,0,0,.4) 100%), url(../img/banner-quimica-mujer.png);
        height: 400px;
        .fila-contenido {
            height: calc(90%);
        }
        p, h3, .autor {
            color: $blanco;
        }
        h3 {
            margin-bottom: 0;
        }
    }
    .carousel-grid {
        height: calc(100% - 65px);
        transition: all 1s ease-in;
    }
    .parrafo-carousel-hero {
        font-size: 4vmin;
        margin-top: 40px;
        color: $negroClaro;
    }
    .contenedor-icono-scroll {
        position: absolute;
        padding-bottom: 64px;
        z-index: 10; 
        .icono-scroll {
            position: relative;
            transition: all 1s ease-in;
            &.bajar {
                top: 65px;
            }
            &.subir {
                top: 0;
            }
        }
    }
    .atomo-bg-hero {
        background-image: url(../img/atomo-bg-hero.png);
        width: 200px;
        height: 200px;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        bottom: -20px;
        right: 0;
        z-index: 1;
        @include tabletChica {
            width: 350px;
            height: 350px;
        }
    }
}
.redes-sociales-fixed {
    position: fixed;
    top: 50%;
    left: 2%;
    text-align: center;
    z-index: 90;
    .icono {
        &.redes-sociales {
            display: block;
            width: 100%;
            color: $morado2;
            margin-bottom: 20px;
            font-size: 5vmin;
            @include tabletChica {
                font-size: 3vmin;
                margin-bottom: 15px;
            }
            &.webinar {
                color: $blanco !important;
            }
        }
    }
}
.escribenos-fixed {
    position: fixed;
    right: 4%;
    top: 90%;
    z-index: 90;
    box-shadow: 0 0 20px 0 rgba($negroPuro, .4);
    .btn {
        transition: all .6s ease;
        &.btn-primary {
            // &:hover {
            //     background-color: $blanco;
            //     color: $morado;
            //     .icono {
            //         fill: $morado;
            //     }
            // }
            &.change-color {
                background-color: $blanco;
                color: $morado;
                .icono {
                    fill: $morado;
                }
            }
        }
    }
    .icono {
        fill: $blanco;
    }
}
.buscador-general {
    position: fixed;
    top: 65px;
    right: 0;
    z-index: $index-90;
    width: 300px;
    padding: 20px;
    background-color: $blanco;
    box-shadow: 0 0 20px 0 rgba($negroPuro, .2);
    transform: scale(0);
    transition: all .2s ease-in-out;
    &.arriba {
        top: 0;
    }
    &.abajo {
        top: 65px;
    }
    &.active {
        transform: scale(1);
    }
    input {
        letter-spacing: 2px;
    }
}

